<template>
  <v-dialog v-model="showDialog" max-width="800px">
    <v-card>
      <v-card-title>
        <span v-html="this.$t('contacts_overview_tab_dialog_header')" />
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="">
          <v-row>
            <v-col cols="12" sm="12" md="12" xl="6">
              <v-form ref="addressForm">
                <v-row>
                  <v-col cols="12">
                    <!-- Anrede -->
                    <v-text-field
                      v-model.trim="contactAddress.salutation"
                      type="text"
                      id="search_ApplicationUser_salutation"
                      :label="$t('contacts_overview_tab_address_edit_salutation')"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <!-- Name/Firma -->
                    <v-text-field
                      v-model.trim="contactAddress.name1"
                      type="text"
                      id="contacts_overview_tab_address_edit_name"
                      :label="$t('contacts_overview_tab_address_edit_name')"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <!-- Vorname / Zusatz -->
                    <v-text-field
                      v-model.trim="contactAddress.name2"
                      type="text"
                      id="contacts_overview_tab_address_edit_fistname"
                      :label="$t('contacts_overview_tab_address_edit_fistname')"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <!-- zuhanden -->
                    <v-text-field
                      v-model.trim="contactAddress.attention"
                      type="text"
                      id="contacts_overview_tab_address_edit_attn"
                      :label="$t('contacts_overview_tab_address_edit_attn')"
                    />
                  </v-col>
                  <v-col cols="12">
                    <!-- Strasse -->
                    <v-text-field
                      v-model.trim="contactAddress.address1"
                      type="text"
                      id="contacts_overview_tab_address_edit_street"
                      :label="$t('contacts_overview_tab_address_edit_street')"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <!-- Postfach -->
                    <v-text-field
                      v-model.trim="contactAddress.address2"
                      type="text"
                      id="contacts_overview_tab_address_edit_pobox"
                      :label="$t('contacts_overview_tab_address_edit_pobox')"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <!-- PLZ -->
                    <v-text-field
                      ref="zipField"
                      v-model.trim="contactAddress.zip"
                      type="number"
                      id="zip"
                      name="zip"
                      :label="$t('contacts_overview_tab_address_edit_zip')"
                      :rules="[zipRule]"
                    />
                  </v-col>
                  <v-col cols="12" sm="8">
                    <!-- Ort-->
                    <v-combobox
                      v-if="this.contactAddress.country === 'CH'"
                      v-model="contactAddress.locality"
                      id="locality"
                      name="locality"
                      :items="localityNames"
                      item-text="locality"
                      item-value="locality"
                      :label="$t('contacts_overview_tab_address_edit_location')"
                      :rules="[rules.required]"
                    ></v-combobox>
                    <v-text-field
                      v-else
                      v-model.trim="contactAddress.locality"
                      id="locality"
                      name="locality"
                      item-text="locality"
                      item-value="locality"
                      :label="$t('contacts_overview_tab_address_edit_location')"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <!-- Land -->
                    <v-select
                      v-model="contactAddress.country"
                      :items="countries"
                      :item-title="$route.params.langKey"
                      item-value="name"
                      id="contacts_overview_tab_address_edit_country"
                      :label="$t('contacts_overview_tab_address_edit_country')"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <!-- Sprache-->
                    <v-select
                      v-model="contactAddress.languageId"
                      :items="languages"
                      id="contacts_overview_tab_address_edit_language"
                      :label="$t('contacts_overview_tab_address_edit_languageCode')"
                      :item-title="$route.params.langKey"
                      item-value="id"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <!-- E-Mail-->
                    <div class="d-flex">
                      <v-text-field
                        v-model.trim="contactAddress.email"
                        type="email"
                        id="contacts_overview_tab_address_edit_email"
                        :label="$t('contacts_overview_tab_address_edit_email')"
                        :rules="[rules.email, rules.required]"
                      />
                      <a :href="`mailto:` + contactAddress.email">
                        <v-btn class="ma-0 action-btn" text icon color="blue lighten-2">
                          <v-icon>mdi-email</v-icon>
                        </v-btn>
                      </a>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <!-- Telefon 1-->
                    <div class="d-flex">
                      <v-text-field
                        v-model.trim="contactAddress.phone1"
                        type="phone"
                        id="contacts_overview_tab_address_edit_phone1"
                        :label="$t('contacts_overview_tab_address_edit_phone1')"
                      />
                      <a :href="`tel:` + $formatPhone(contactAddress.phone1)">
                        <v-btn class="ma-0 action-btn" text icon color="blue lighten-2">
                          <v-icon>mdi-phone</v-icon>
                        </v-btn>
                      </a>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <!-- Hinweis Telefon 1-->
                    <v-text-field
                      v-model.trim="contactAddress.phone1Hint"
                      type="text"
                      id="contacts_overview_tab_address_edit_phone1Hint"
                      :label="$t('contacts_overview_tab_address_edit_phone1Hint')"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <!-- Telefon 2-->
                    <div class="d-flex">
                      <v-text-field
                        v-model.trim="contactAddress.phone2"
                        type="phone"
                        id="contacts_overview_tab_address_edit_phone2"
                        :label="$t('contacts_overview_tab_address_edit_phone2')"
                      />
                      <a :href="`tel:` + $formatPhone(contactAddress.phone2)">
                        <v-btn class="ma-0 action-btn" text icon color="blue lighten-2">
                          <v-icon>mdi-phone</v-icon>
                        </v-btn>
                      </a>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <!-- Hinweis Telefon 2-->
                    <v-text-field
                      v-model.trim="contactAddress.phone2Hint"
                      type="text"
                      id="contacts_overview_tab_address_edit_phone2Hint"
                      :label="$t('contacts_overview_tab_address_edit_phone2Hint')"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <!-- Telefon 3-->
                    <div class="d-flex">
                      <v-text-field
                        v-model.trim="contactAddress.phone3"
                        type="phone"
                        id="contacts_overview_tab_address_edit_phone3"
                        :label="$t('contacts_overview_tab_address_edit_phone3')"
                      />
                      <a :href="`tel:` + $formatPhone(contactAddress.phone3)">
                        <v-btn class="ma-0 action-btn" text icon color="blue lighten-2">
                          <v-icon>mdi-phone</v-icon>
                        </v-btn>
                      </a>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <!-- Hinweis Telefon 3-->
                    <v-text-field
                      v-model.trim="contactAddress.phone3Hint"
                      type="text"
                      id="contacts_overview_tab_address_edit_phone3Hint"
                      :label="$t('contacts_overview_tab_address_edit_phone3Hint')"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>

            <v-col cols="12" sm="12" md="12" xl="6">
              <!-- Addressvorschau -->
              <address-preview v-bind:address="contactAddress"></address-preview>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn id="btn-submit" color="primary" class="button-margin button-top-margin shadow-none" @click="onSubmit" :loading="this.loading">
              <span v-html="$t('contacts_overview_tab_address_edit_save')" />
            </v-btn>
            <v-btn id="btn-edit-cancel" color="secondary" class="button-margin button-top-margin shadow-none" @click="navigateBackToSearch()"
              ><span v-html="$t('contacts_overview_tab_back_button')"
            /></v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import addressPreview from '../masterdata/addressPreview'
import { mapGetters } from 'vuex'
import { ContactAddress } from '@/views/contacts/contact'
import _ from 'lodash'
import { useCountriesStore } from '@/store/enums/CountriesStore'
import { useLanguagesStore } from '@/store/enums/LanguagesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'addressDialog',
  components: { addressPreview },
  props: { contactId: { type: Number, required: true }, defaultAddressId: { type: Number, default: undefined, required: false } },
  emit: ['reload'],
  data() {
    return {
      contactAddress: new ContactAddress(),
      showDialog: false,
      addressMode: 'insert', //'insert' | 'update'
      addressId: Number,
      fieldDef: {
        zip: {
          min: 4,
          max: 4
        },
        locality: {
          min: 2,
          max: 40
        }
      },
      localities: [],
      loading: false,
      rules: {
        required: (value) => !!value || this.$t('form_field_required'),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value) || this.$t('form_field_invalid_email')
        }
      }
    }
  },
  computed: {
    localityNames() {
      return this.localities.map((x) => x.localityLong)
    },
    countries() {
      return useCountriesStore().Countries
    },
    languages() {
      useLanguagesStore().languages
    }
  },
  defaultAddress() {
    return this.addresses.find((x) => x.default === true || x.contactType === 'MASTER_DATA')
  },
  methods: {
    load() {
      this.$nextTick(async () => {
        if (!this.addressId || typeof this.addressId !== 'number') {
          return
        }
        try {
          this.loading = true
          const response = await this.axios.get(apiURL + '/contactAddresses/' + this.addressId + '', { headers: { Accept: 'application/json' } })
          this.contactAddress = await response.data
        } catch (e) {
          this.contactAddress = new ContactAddress()
          showError(e)
        } finally {
          this.loading = false
        }
      })
    },
    async onSubmitPost() {
      try {
        const result = await this.axios.post(
          apiURL + '/contactAddresses/',
          {
            ..._.omit(this.contactAddress, ['addressId']),
            ...{ contactId: this.contactId },
            ...{ locality: this.contactAddress.locality.localityLong || this.contactAddress.locality },
            ...{ isDefault: false },
            ...{ isDefaultEncashment: false },
            ...{ contactAddressType: 'INDEPENDENT' }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          this.closeDialog()
        }
      } catch (e) {
        showError(e)
        this.loading = false
      }
    },
    async onSubmitPatch() {
      try {
        this.loading = true
        const result = await this.axios.patch(
          apiURL + '/contactAddresses/' + this.contactAddress.id,
          {
            ..._.omit(this.contactAddress, ['contactId', 'addressId', 'isDefault', 'isDefaultEncashment'])
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          this.closeDialog()
        }
      } catch (e) {
        showError(e)
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    zipRule() {
      if (this.contactAddress.country !== 'CH') {
        return true
      }
      if (this.contactAddress.zip === undefined || typeof parseInt(this.contactAddress.zip) != 'number') {
        return false
      }
      //im schweizer range
      if (this.contactAddress.country === 'CH' && (parseInt(this.contactAddress.zip) < 999 || parseInt(this.contactAddress.zip) > 9999)) {
        return false
      }
      /* localities will be async loded just now.. revalidate this field when loaded. eg: (await this.$refs.zipField.validate()).valid*/
      return this.localities.length > 0
    },
    async onSubmit() {
      if (!(await this.$refs.addressForm.validate()).valid) {
        return
      }
      if (this.addressMode === 'insert') {
        this.onSubmitPost()
      } else {
        this.onSubmitPatch()
      }
      this.showDialog = false
    },
    navigateBackToSearch() {
      this.showDialog = false
    },
    closeDialog() {
      this.addressId = null
      this.$emit('reload')
      this.showDialog = false
    },
    openDialog(id) {
      this.addressId = id
      this.addressMode = 'update'
      this.load()
      this.showDialog = true
    },
    openDialogCreate() {
      this.contactAddress = new ContactAddress()
      this.contactAddress.contactId = this.contactId
      this.addressMode = 'insert'
      this.localities = []
      this.load()
      this.showDialog = true
    }
  },
  watch: {
    'contactAddress.zip': async function (newVal) {
      try {
        if (this.contactAddress.country === 'CH' && newVal >= 1000) {
          const localityByZip = await this.axios.get(apiURL + '/postcodes/findByZip', { params: { zip: newVal } }) //immer alle
          this.localities = await localityByZip.data.content
          if (this.localities.length > 0) {
            this.contactAddress.locality = this.localities[0].localityLong
            this.$refs.zipField.validate()
          }
        }
      } catch (e) {
        showError(e)
      }
    }
  },
  mounted() {
    useCountriesStore().fetchCountries()
    this.load()
  }
})
</script>
